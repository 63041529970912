.nav-mainbox {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: start;
}

.sub-header-title {
  flex: 1;
}

.sub-header-menu {
  flex: 1;
  display: flex;
}

ul {
  &.sub-header-bar {
    margin-bottom: 0;

    li {
      display: inline;
      list-style: none;

      &.topbar-devider {
        top: -1px;
        padding: 0;
        font-size: 8px;
        position: relative;
        margin: 0 9px 0 5px;
        font-family: Tahoma;
        border-right: solid 2px #000;
      }
      &:not(.topbar-devider) {
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

a:hover {
  text-decoration: none;
}

.navbar__link:hover {
  transform: translateY(-2px);
  text-decoration: none;
}

.navbar__link--active {
  color: #72c02c !important;
}

.navbar__link {
  margin: 0.5em;
  font-size: 1em;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  text-decoration: none;
  color: #181818;
  border-bottom: 3px solid transparent;
}
