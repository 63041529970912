.header {
  width: 100%;
  .topbar {
    ul {
      &.loginbar {
        margin-bottom: 0;
        li {
          display: inline;
          list-style: none;
          &.topbar-devider {
            top: -1px;
            padding: 0;
            font-size: 8px;
            position: relative;
            margin: 0 9px 0 5px;
            font-family: Tahoma;
            border-right: solid 1px #bbb;
          }
          &:not(.topbar-devider) {
            &:hover {
              cursor: pointer;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.header-img {
  max-width: 60%;
  // width: 500px;
}

.header-navbar__link {
  margin: 1em;
  font-size: 1.3em;
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  text-decoration: none;
  text-indent: 0.1em;
  color: #181818;
  border-bottom: 3px solid transparent;
}

.nav-container {
  display: flex;
}

.profile-img {
  font-size: 25px;
  // color: rgb(255, 255, 255);
}

.logo-img {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.navbar-collapse {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav a:hover {
  text-decoration: none;
}

nav a:focus {
  text-decoration: none;
}

.header-navbar__link:hover {
  transform: translateY(-2px);
  border-bottom: 3px solid #72c02c;
  text-decoration: none;
}

.header-navbar__link--active {
  border-bottom: 3px solid #72c02c;
  transition: border-bottom 0.5s ease-in-out;
  text-decoration: none;
  color: #72c02c;
}
.dropbtn {
  background-color: transparent;
  // color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropdown {
  display: inline-block;
  margin-right: 20px;
}
p {
  padding: 1rem;
}

// .dropdown {
//   position: relative;
//   display: inline-block;
// }

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}

// .dropdown:hover .dropbtn {background-color: #72c02c;}

.right-float {
  float: right;
}

.down-caret {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 5px 0 5px;
  border-color: #000 transparent transparent transparent;
  display: inline-block;
  margin-left: 6px;
  top: -3px;
  position: relative;
  transform: rotate(0deg);
  transition: all 0.25s ease-in;
}
