footer{
  // position: relative;
  background: #333;
  
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  
  }
  @media screen and (max-width: 1500px) {
  footer {
  position: relative;
  }
  }
  
  @media screen and (min-width: 900px) {
  footer {
  position: absolute;
  }
  }
 footer:before{
  content: '';
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: #333;
 }
  
 .footer-bottom{
  width: 100%;
   padding: 25px 0;
   color: #fff;
   background: #000;
 }
  
 .footer-bottom span {
  color: #72c02c;
 }