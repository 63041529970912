.ReactTable .rt-table .rt-thead {
  background-color: #72c02c;
  border: solid 0.1px gray;
  padding: 10px;
  font-weight: 900;
  color: white;
  text-align: justify;
}
.ReactTable .rt-thead .rt-tr {
  text-align: start;
}
.widgets_div {
  position: relative;
  height: 50px;
  margin-right: 15px;
  margin-top: 10px;
}
.btnsize {
  height: 35px;
  width: 35px;
}
.contents {
  position: absolute;
  right: 0px;
}
.widgets_div .icon_div {
  display: inline;
  margin-right: 5px;
}

.widgets_div .icon_div span i {
  font-size: 15px;
}

.widgets_div .text_div {
  display: inline;
}

.ReactTable .rt-table .rt-tbody {
  border: solid 0.1px #72c02c;
}

.rt-tr-group {
  background: white;
}

.rt-tr-group:nth-of-type(even) {
  background: #d8f5c0;
}

.head-tittle {
  border-bottom: 2px solid #72c02c;
  display: inline-block;
}

.filter-form {
  display: flex;
}

.form-title {
  flex: 1;
}
.filter-name {
  flex: 2;
}

.pagination-bottom .-pagination {
  width: 50%;
  float: right;
}
