// .container {
//   width: 100%;
//   height: 100%;
//   background: transparent;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   justify-content: center;
// }

#dialog-root {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  box-shadow: 0px 10px 10px -5px black;

  .dialog-wrapper {
    position: relative;
    height: 300px;
    width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: white;
    border: black 2px solid;
    border-radius: 5px;
    animation: slideInDown 600ms ease-in-out;
  }
}

.btn1 {
  font-size: 15px;
  padding: 5px 10px;
  font-family: sans-serif;
  background: white;
  color: black;
  border: black 1px solid;
  border-radius: 3px;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.3);
  }
}

.btn1-close {
  position: absolute;
  font-size: 20px;
  right: 20px;
  top: 15px;
  color: grey;

  &:hover {
    color: black;
    cursor: pointer;
  }
}

@keyframes slideInDown {
  0% {
    transform: translateY(-30px);
    opacity: 0.1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

/* Add styles to the form container */
.form-container {
  max-width: 300px;
  padding: 10px;
  background-color: white;
}

/* Full-width input fields */
.form-container input[type="text"],
.form-container input[type="password"] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  border: none;
  background: #f1f1f1;
}

/* When the inputs get focus, do something */
.form-container input[type="text"]:focus,
.form-container input[type="password"]:focus {
  background-color: #ddd;
  outline: none;
}

/* Set a style for the submit/login button */
.form-container .btn {
  background-color: #4caf50;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
  opacity: 0.8;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  background-color: red;
}

/* Add some hover effects to buttons */
.form-container .btn:hover,
.open-button:hover {
  opacity: 1;
}
