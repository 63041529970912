body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    position: relative;
    margin: 0;
    padding-bottom:15%;

    // padding-bottom: 100px; //height of the footer
    box-sizing: border-box;
  /*******************************************our css***************************************************/

  .mrg-top-30 {
    margin-top: 15px;
  }
  @media screen and (max-width: 768px) {
  .mrg-top-30 {
    margin-left: 15px;
  }
  }
  .mrg-left-20 {
    margin-left: 20px;
  }

  .hidden-obj {
    display: none;
  }

  .formio-hidden {
    &.form-group {
      margin-bottom: 0;
    }
  }

  .file-name {
    font-weight: bold;
    color: #1e7e34;

    &::before {
      content: " ";
      height: 14px;
      width: 14px;
      border-radius: 100%;
      color: #1e7e34;
    }
  }

  .nav-mainbox {
    margin-left: 118px;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  .mainbox {
    margin-left: 118px;
    margin-top: 30px;
    margin-bottom: 20px;
    border: solid;
    border-width: thin;
    border-color: #f3f0f0;
    padding-right: 0px;
    padding-left: 0px;

    .headline {
      border-bottom: 1px dotted #e4e9f0;
      margin-left: 64px;
      .head-tittle {
        border-bottom: 2px solid #72c02c;
        display: inline-block;
        margin: 30px 0 -2px -24px;
        padding-bottom: 5px;
      }
    }
    .loan-p {
      margin-top: 10px;
    }
    .horizontal-line {
      margin-right: -20px;
      margin-left: -20px;
    }
    .horizontal-line-com {
      margin-right: -40px;
      margin-left: -40px;
    }
    i.fa {
      color: lightgray;
    }
    .btn-success {
      border-radius: 0;
      &.btn-continue-loan {
        margin-left: -14px;
      }
      &.btn-save-loan {
        margin-left: 0px;
      }
      &.btn-continue-ledger {
        margin-left: -79px;
      }
      &.btn-save-ledger {
        margin-left: -72px;
      }
      &.btn-continue-finance {
        margin-left: -24px;
      }
    }
  }

  @media only screen and (max-width: 767px) {
    .mainbox {
      margin-left: 15px;
      .headline {
        .head-tittle {
          margin-left: -52px;
        }
      }
      .horizontal-line {
        margin-right: -17px;
        margin-left: -17px;
      }
      .horizontal-line-com {
        margin-right: -10px;
        margin-left: -10px;
      }
      .btn-success {
        border-radius: 0;
        &.btn-save {
          margin-left: 84px;
          margin-top: -91px;
        }
        &.btn-continue {
          margin-left: 230px;
          margin-top: -163px;
        }
        &.btn-continue-loan {
          margin-left: 0px;
        }
        &.btn-continue-ledger {
          margin-left: 0px;
        }
        &.btn-save-ledger {
          margin-left: 0px;
        }
        &.btn-continue-finance {
          margin-left: 0px;
        }
        &.btn-save-finance {
          margin-left: 0px;
        }
      }
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}