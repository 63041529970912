.inline-flex-parent {
  display: inline-flex;
  width: 100%
}
.summary-form {
  width: 50%
}
.accordion {
  margin: 0 auto;
  width: 100%;

  
  .title {
    padding: 30px 0 0 30px;
    cursor: pointer;
    transform: translate3d(0, 0, 0);
    color: black;
    position: relative;
    font-size: 15px;
    background: #e6e6e6;
    margin-bottom: -1px;
    text-align: left;

    &::after {
      content: "+";
      font-size: 18px;
      color: black;
      transition: transform .5s ease-in-out;
      position: absolute;
      right: 30px;
      font-family: monospace;
    }

    &.is-expanded {
      transition: background .5s;
      background: #e6e6e6;
      
      
      &::after {
        content: "-";
        transform: rotate(-360deg);
        padding-top: 2px

      }
    }
  }

  .content {
    overflow: hidden;
    max-height: 0;
    transition: max-height .5s;
    margin: 0;
    padding: 0 30px;
    border: solid 1px #e6e6e6;
    border-top: 0;
    background: #e6e6e6;

    p {
      padding: 20px 0;
      margin: 0;
      opacity: 0;
      transition: .5s;
    }
    
    &.is-expanded {
      max-height: 500px;
      overflow: hidden;
      p {
        opacity: 1;
      }
    }
  }
  
  &:after {
    width: 100%;
    height: 10px;
    display: block;
    background: #e6e6e6;
    content: '';
  }
}

