  #app{
    border-radius:3px;
    border: 1px solid #e5e5e5;
    margin:15px;
    background-color:white;
  }
  
  .tab{
    width:100%;
    font-family:sans-serif;
    color:#444;
  }
  .button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }
  ul.inline{
    list-style:none;
    padding: 0;
    margin-bottom:0;
    -webkit-margin-before: 0;
    -webkit-margin-after: 0;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 0px;
    border-bottom:2px solid #72c02c;
    li{
      display:inline-block;
      margin-left:0;
      padding:10px;
      transition: all .5s;
      font-family:sans-serif;
      font-weight:300;
      cursor:pointer;
      color:#000;
      &.selected{
        background-color: #72c02c;
        color:white;
      }
    }
}

.loan-elg-wrap > div {
    width: 220px;
    display: inline-block;
}

.loan-elg-content { 	
    position:relative;
    background-color: #72c02c;
    height:  60px;
    width:70px !important;
    border-radius: 4px;
    text-align: center;
}

.loan-elg-content > i {
    position:relative;
    top: calc(40% - 10px);
    font-size: 30px;
    color: white;
}

.loan-elg-content:after {
    z-index: -1;
    position: absolute;
    top: 98.1%;
    left: 60%;
    margin-left: -25%;
    content: '';
    width: 0;
    height: 0;
    border-top: solid 10px #72c02c;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
}

.detail-content {
    .mainbox {
        margin-left: 1px !important;
    }
}

.file-upload-content {
    width: 100%;
}

.list-group {
    .list-group-item {
        border-bottom: 1px solid #eee;
        border-top: 1px solid #eee;
        height: 70px;
    }
}

.file-upload-bg-red {
    display: block;
    width: 50px;
    height: 50px;
    background: #e74c3c;
    text-align: center;
    font-size: 20px;
}

.file-upload-bg-green {
    display: block;
    width: 50px;
    height: 50px;
    background: green;
    text-align: center;
    font-size: 20px;
}
  
.file-upload-name {
    margin: 0 5px;
}

.icon-bg-red {
    position:relative;
    color: #fff;
    top: calc(40% - 10px);
}

.icon-bg-green {
    position:relative;
    color: #fff;
    top: calc(40% - 10px);
}

.financial-title {
    background-color: #ABA8A8 !important;
    color: #fff !important;
}

.credit-file-upload {
    background-color: #32ad57 !important;
    color: #fff !important;
}
    
